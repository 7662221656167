$font-one: "Futura PT", sans-serif;
$font-title: "Gilroy", sans-serif;
$font-open-sans: "Open Sans", sans-serif;


$white: #fff;
$light_gray: #3d414d;
$middle_gray: #353a46;
$dark_gray: #2a2e38;
$light_green: #62e892;
$green: #54d682;
$black: #202020;
$selection_color: #0ab29c;
$black-opacity: rgba(0,0,0,0.6)
